<template>
  <div>
    <vx-card>
      <div class="flex flex-wrap justify-between items-center">
        <div class="mb-4 md:mb-0 mr-4">
          <div class="flex flex-wrap">
            <vs-button class="mr-3" color="primary" type="filled" icon-pack="feather" icon="icon-arrow-left" @click="$router.go(-1)"></vs-button>
            <vs-button class="mr-3" color="primary" type="border" icon-pack="feather" icon="icon-refresh-cw" @click="getInitData"></vs-button>
          </div>
        </div>
        <div class="flex flex-wrap items-center sm:justify-between w-full sm:w-auto">
          <div class="vx-col w-full sm:w-auto flex justify-between">
            <vs-input icon-pack="feather" icon="icon-search" class="w-full" placeholder="Cari Item Pekerjaan" v-model="search"/>
          </div>
        </div>
      </div>
    </vx-card>

    <!--loading-->
    <vs-progress indeterminate color="primary" :height="1" :class="{ 'invisible': !isLoading }"/>

    <!--empty-->
    <div class="w-full mt-10" v-if="isEmpty && !isLoading">
      <div class="flex items-center justify-center">
        <img class="w-64 h-auto m-4 opacity-75" src="@/assets/svg/under_construction.svg" alt="Empty"/>
      </div>
      <p class="text-center opacity-75">Tidak ada data.</p>
    </div>

    <!--list pekerjaan-->
    <div class="vx-row">
      <div v-for="item in listPekerjaan" :key="item.id" class="vx-col w-full mb-2 sm:w-1/2 md:w-1/3 cursor-pointer">
        <vx-card @click="goToListItemPekerjaan(item.id)">
          <div class="flex w-full items-center">
            <div class="w-30 h-30 mr-3">
              <vs-avatar :text="item.no_urut.toString()" size="30px"/>
            </div>
            <div class="w-full">
              <h5>{{ item.nama_item_progress_pekerjaan }}</h5>
              <p class="text-sm mt-1" :class="item.progress_percentage < 100 ? 'text-primary' : 'text-success'">Progress: {{ item.progress_percentage }}%</p>
              <p class="text-sm mt-1">Bobot Tercapai: {{ item.bobot_reached_percentage | idr }} / {{ item.bobot }}%</p>
            </div>
            <div class="flex h-full">
              <vs-icon v-if="item.progress_percentage < 100" size="small" icon-pack="material-icons" icon="hourglass_empty" color="warning"/>
              <vs-icon v-else size="small" icon-pack="material-icons" icon="check" color="success"/>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import SpkRepository from '@/repositories/proyek/spk-repository'
import _ from 'lodash'

export default {
  name: 'ProgressUnitPekerjaan',
  props: {
    isRouteProgressUnit: { required: true, type: Boolean } // Progress Unit or Progress Proyek
  },
  mounted () {
    this.getInitData()
  },
  data () {
    return {
      isLoading: false,
      search: null,
      pekerjaan: []
    }
  },
  computed: {
    listPekerjaan () {
      const items = this.pekerjaan
      const search = this.search
      if (search) {
        return _.filter(items, (item) => {
          return item.nama_item_progress_pekerjaan.toLowerCase().includes(search)
        })
      }
      return items
    },
    isEmpty () {
      return this.listPekerjaan.length < 1
    }
  },
  methods: {
    getInitData () {
      this.isLoading = true

      const idSpk = this.$route.params.idSpk
      const params = {}
      if (this.isRouteProgressUnit) {
        params.id_properti_unit = this.$route.params.idPropertiUnit
      }

      SpkRepository.getSpkPekerjaan(idSpk, params)
        .then(response => {
          this.pekerjaan = response.data.data
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$router.push({ name: '404' })
          } else {
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    goToListItemPekerjaan (idSpkPekerjaan) {
      this.$router.push({ name: 'proyek.progressUnit.spkPekerjaan.history', params: { idSpkPekerjaan: idSpkPekerjaan } })
    }
  }
}
</script>
